
var speed = 250;
$(function () {
    $('body').on("click", '#main-nav .sublink-closed', function (e) {
        e.preventDefault();
        var navType = $(this).data("nav-type"); // only set for top level nav items
        if (navType == "top") {
            // close open menus if we are opening a new top level one
            $('.sublink-open').next('ul').slideUp(speed);
            // set classes
            $('.sublink-open').addClass('sublink-closed');
            $('.sublink-open').removeClass('sublink-open');
        }
        $(this).next('ul').slideDown(speed);
        $(this).addClass('sublink-open');
        $(this).removeClass('sublink-closed');
        // arrows
        $(this).find('.arrow-up').show();
        $(this).find('.arrow-down').hide();
    });

    $('body').on('click', '#main-nav .sublink-open', function (e) {
        e.preventDefault();
        $(this).next('ul').slideUp(speed);
        $(this).removeClass('sublink-open');
        $(this).addClass('sublink-closed');
        $(this).find('.arrow-up').hide();
        $(this).find('.arrow-down').show();
    });
});

var menuOpen = false;
$(function () {

    // mobile menu    
    $('.mobile-menu-trigger').click(function () {
        if (menuOpen) {
            $('#mobile-left').fadeOut();
            $('.the-website').removeClass("left-menu-visible");
            $('.the-website').animate(
                { left: "0%" }, // what we are animating
                {
                    duration: speed, // how fast we are animating
                    easing: 'swing', // the type of easing
                });
            menuOpen = false;
        }
        else {
            $('#mobile-left').fadeIn();
            $('.the-website').addClass("left-menu-visible");
            $('.the-website').animate(
                { left: "80%" }, // what we are animating
                {
                    duration: speed, // how fast we are animating
                    easing: 'swing', // the type of easing
                });
            menuOpen = true;
        }
    });




    $(document).on("click", "#mobile-left .sublink-closed", function (e) {
        e.preventDefault();
        $(this).next('ul').slideDown(speed);
        $(this).addClass('sublink-open');
        $(this).removeClass('sublink-closed');
        // arrows
        $(this).find('.arrow-up').show();
        $(this).find('.arrow-down').hide();
    });

    $(document).on("click", "#mobile-left .sublink-open", function (e) {
        e.preventDefault();
        $(this).next('ul').slideUp(speed);
        $(this).removeClass('sublink-open');
        $(this).addClass('sublink-closed');
        $(this).find('.arrow-up').hide();
        $(this).find('.arrow-down').show();
    });
});